let instances = [];

export default class cEH_backend_struktur {

    static getInstances() {
        return instances;
    }

    constructor($target) {

        console.log('Module initialized: cEH_backend_struktur');

        this.$target = $target;
        this.$editIcons = $target.find('.edit-icon');

        this.$colSender = $target.find('.col_sender');
        this.$colSerien = $target.find('.col_serien');
        this.$colSeasons = $target.find('.col_seasons');

        this.$addSendung = $target.find('.addSendung');
        this.$addStaffel = $target.find('.addStaffel');

        this.$serieCollapse = this.$colSerien.find('.collapse');
        this.$seasonCollapse = this.$colSeasons.find('.collapse');

        this.currentSenderID = null;
        this.currentSerieID = null;

        //Stoppe Handler zum Verschieben der Buttons, wenn das Edit-Icon geklickt wird
        this.$editIcons.on('mousedown dragstart click', this.onEdit.bind(this));

        this.$serieCollapse.on('shown.bs.collapse', this.openSender.bind(this))
        this.$serieCollapse.on('show.bs.collapse', this.collapseOtherSeries.bind(this));

        this.$seasonCollapse.on('show.bs.collapse', this.collapseOtherSeasons.bind(this));
        this.$seasonCollapse.on('shown.bs.collapse', this.openSeason.bind(this));

        this.$serieCollapse.on('hidden.bs.collapse', this.closeSender.bind(this));
        this.$seasonCollapse.on('hidden.bs.collapse', this.closeSeason.bind(this));

        this.$addSendung.on('click', this.onAddSendung.bind(this));
        this.$addStaffel.on('click', this.onAddStaffel.bind(this));

        instances.push(this);

    }

    onEdit(e) {
        e.stopPropagation();
    }

    collapseOtherSeasons(e) {
        this.$seasonCollapse.not(e.target).collapse('hide');
    }

    collapseOtherSeries(e) {
        this.$serieCollapse.not(e.target).collapse('hide');
    }

    onAddSendung() {
        if (this.$addSendung.hasClass('disabled')) {

        } else {
            let url = this.$addSendung.data('url');
            url = url.replace('%id%', this.currentSenderID);
            location.href = url;
        }
    }

    onAddStaffel() {
        if (this.$addStaffel.hasClass('disabled')) {

        } else {
            let url = this.$addStaffel.data('url');
            url = url.replace('%id%', this.currentSerieID);
            location.href = url;
        }
    }

    updateAddButtons() {

        let that = this;

        this.$colSender.find('.btn[data-id]').removeClass('active').filter(function () {
            return this.dataset.id === that.currentSenderID;
        }).addClass('active');

        this.$colSerien.find('.btn[data-id]').removeClass('active').filter(function () {
            return this.dataset.id === that.currentSerieID;
        }).addClass('active');

        if (this.currentSenderID !== null) {
            this.$addSendung.removeClass('disabled');
        } else {
            this.$addSendung.addClass('disabled');
        }

        if (this.currentSerieID !== null) {
            this.$addStaffel.removeClass('disabled');
        } else {
            this.$addStaffel.addClass('disabled');
        }

    }

    openSender(e) {

        console.log("openSender")

        let triggerData = $(e.target).data('bs.collapse');
        let triggerElement = null;
        let triggerID = null;

        if (triggerData) {
            if (triggerData._triggerArray.length > 0) {
                triggerElement = triggerData._triggerArray[0];
                if (triggerElement) {
                    if (triggerElement.dataset.id) {
                        triggerID = triggerElement.dataset.id;
                    }
                }
            }
        }

        if (triggerID) {
            this.currentSenderID = triggerID;
            this.currentSerieID = null;
            this.updateAddButtons();
            console.log("currentSenderID", triggerID);
        }

    }

    closeSender(e) {

        console.log("closeSender")

        let triggerData = $(e.target).data('bs.collapse');
        let triggerElement = null;
        let triggerID = null;

        if (triggerData) {
            if (triggerData._triggerArray.length > 0) {
                triggerElement = triggerData._triggerArray[0];
                if (triggerElement.classList.contains('active')) {
                    triggerElement.classList.remove('active');
                    this.currentSerieID = null;
                    this.currentSenderID = null;
                    this.updateAddButtons();
                }
            }
        }

    }

    openSeason(e) {

        console.log("openSeason")

        let triggerData = $(e.target).data('bs.collapse');
        let triggerElement = null;
        let triggerID = null;

        if (triggerData) {
            if (triggerData._triggerArray.length > 0) {
                triggerElement = triggerData._triggerArray[0];
                if (triggerElement) {
                    if (triggerElement.dataset.id) {
                        triggerID = triggerElement.dataset.id;
                    }
                }
            }
        }

        if (triggerID) {
            this.currentSerieID = triggerID;
            this.updateAddButtons();
            console.log("currentSerieID", triggerID);
        }

    }

    closeSeason(e) {

        console.log("closeSeason")

        let triggerData = $(e.target).data('bs.collapse');
        let triggerElement = null;
        let triggerID = null;

        if (triggerData) {
            if (triggerData._triggerArray.length > 0) {
                triggerElement = triggerData._triggerArray[0];
                if (triggerElement.classList.contains('active')) {
                    triggerElement.classList.remove('active');
                    this.currentSerieID = null;
                    this.updateAddButtons();
                }
            }
        }
    }

}