let instances = [];

export default class ModalForm {

    static getInstances() {
        return instances;
    }

    constructor($form) {

        console.log('Module initialized: ModalForm');

        this.$form = $form;
        this.action = $form.prop('action');
        this.$success = $form.find('.modal-form-success');
        this.$error = $form.find('.modal-form-error');

        this.id = 'modal-file-' + $form.data('id');
        this.$fileRow = $('#file-row-' + $form.data('id'));
        this.$inputValidFrom = $form.find('[name="valid_from"]');
        this.$inputValidTo = $form.find('[name="valid_to"]');
        this.$reset = $form.find('.modal-file-reset');

        this.$insertFileName = $form.find('.insert-file-name');
        this.$insertFileImage = $form.find('.insert-file-image');

        this.oldFileName = this.$insertFileName.text();
        this.oldImage = this.$insertFileImage.prop('src');
        if (this.$insertFileImage.hasClass('is-missing')) {
            this.oldImage = false;
        }

        this.$inputFile = $form.find('input[type="file"]');

        $form.submit(this.onSubmit.bind(this));
        this.$inputFile.change(this.fileChanged.bind(this));
        this.$reset.click(this.onReset.bind(this));
    }

    onReset(e) {
        e.preventDefault();
        this.$form.trigger('reset');
        this.$inputFile.trigger('change');
    }

    fileChanged(e) {
        let file = e.currentTarget.files[0];
        if (file) {
            this.$form.find('.insert-file-name').text(file.name);
            let reader = new FileReader();
            reader.onload = (e) => {
                this.$form.find('.insert-file-image').attr('src', e.target.result).show();
            };
            reader.readAsDataURL(file);
        } else {
            this.$form.find('.insert-file-name').text(this.oldFileName);
            if (this.oldImage) {
                this.$form.find('.insert-file-image').attr('src', this.oldImage).show();
            } else {
                this.$form.find('.insert-file-image').hide();
            }
        }
    }

    dateToString(date) {

        date = new Date(date);
        console.log(date);
        if(isNaN(date.getTime())){
            return '-';
        }
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();

        if (month < 10) {
            month = '0' + month;
        }

        if (day < 10) {
            day = '0' + day;
        }

        return [day, month, year].join('.');
    }
    changedValid() {
        $('.collapse[id="' + this.id + '"]').collapse('hide');
        this.$error.hide();
        this.$fileRow.find('.file-valid-from').html(this.dateToString(this.$inputValidFrom.val()));
        this.$fileRow.find('.file-valid-to').html(this.dateToString(this.$inputValidTo.val()));
        this.$fileRow.find('.file-valid-seperator').html('&nbsp;bis&nbsp;');
    }
    onSubmit(e) {
        let that = this;
        e.preventDefault();
        $.ajax({
            type: 'POST',
            url: this.action,
            data: new FormData(this.$form.get(0)),
            contentType: false,
            cache: false,
            processData: false,
            dataType: "json",
            success: function (data) {
                if (typeof data === 'boolean') {
                    if (data === true) {
                        that.changedValid();
                    }
                } else {
                    let errors = [];
                    $.each(data, (key, msg) => {
                        errors.push(msg);
                    });
                    let msg = "";
                    $.each(errors, (key, error) => {
                        msg += error + "<br>";
                    });
                    that.$error.find('p').first().html(msg);
                    that.$error.slideDown();
                }
            }
        });
    }
}